import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function (n) { return (_pushScopeId("data-v-c32fb6ba"), n = n(), _popScopeId(), n); };
var _hoisted_1 = { class: "modal-x" };
var _hoisted_2 = { class: "fv-row fv-plugins-icon-container" };
var _hoisted_3 = { class: "text-start py-3 border-bottom" };
var _hoisted_4 = {
    for: "name",
    class: "col-12 mx-2 text-start col-form-label required fw-bold fs-6"
};
var _hoisted_5 = ["disabled"];
var _hoisted_6 = ["disabled"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _component_base_image_upload_single = _resolveComponent("base-image-upload-single");
    var _component_base_row = _resolveComponent("base-row");
    var _component_base_field = _resolveComponent("base-field");
    var _component_base_col = _resolveComponent("base-col");
    var _component_Form = _resolveComponent("Form");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_Form, {
                onSubmit: $setup.submitModal,
                "validation-schema": $setup.validationSchema
            }, {
                default: _withCtx(function (_a) {
                    var errors = _a.errors, isSubmitting = _a.isSubmitting;
                    return [
                        _createElementVNode("h5", _hoisted_3, _toDisplayString($setup.editMode ? _ctx.$t("Update Category") : _ctx.$t("Create Category")), 1),
                        _createVNode(_component_base_row, null, {
                            default: _withCtx(function () { return [
                                _createVNode(_component_base_image_upload_single, {
                                    modelValue: $setup.form.image_path,
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) { return (($setup.form.image_path) = $event); }),
                                    imagePlaceHolder: $setup.form.image_path,
                                    class: "align-self-center mt-2"
                                }, null, 8, ["modelValue", "imagePlaceHolder"])
                            ]; }),
                            _: 1
                        }),
                        _createVNode(_component_base_row, { class: "mt-1" }, {
                            default: _withCtx(function () { return [
                                _createVNode(_component_base_col, { class: "col-md-12 text-left" }, {
                                    default: _withCtx(function () { return [
                                        _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t("Name")), 1),
                                        _createVNode(_component_base_field, {
                                            class: "text-start",
                                            name: "name",
                                            type: "text",
                                            placeholder: _ctx.$t('Enter Name'),
                                            modelValue: $setup.form.name,
                                            "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) { return (($setup.form.name) = $event); }),
                                            rules: "required"
                                        }, null, 8, ["placeholder", "modelValue"])
                                    ]; }),
                                    _: 1
                                })
                            ]; }),
                            _: 1
                        }),
                        _createVNode(_component_base_row, { class: "mt-5 d-flex justify-content-end" }, {
                            default: _withCtx(function () { return [
                                _createElementVNode("button", {
                                    type: "button",
                                    class: "btn btn-light btn-sm me-2",
                                    style: { "max-width": "5rem" },
                                    onClick: _cache[2] || (_cache[2] =
                                        //@ts-ignore
                                        function () {
                                            var args = [];
                                            for (var _i = 0; _i < arguments.length; _i++) {
                                                args[_i] = arguments[_i];
                                            }
                                            return ($setup.closeModal && $setup.closeModal.apply($setup, args));
                                        })
                                }, _toDisplayString(_ctx.$t("Back")), 1),
                                _createElementVNode("button", {
                                    type: "submit",
                                    class: "btn btn-success btn-sm me-2",
                                    disabled: isSubmitting,
                                    onClick: _cache[3] || (_cache[3] = function ($event) { return ($setup.submitModal(true)); }),
                                    style: { "max-width": "8rem" }
                                }, _toDisplayString($setup.editMode ? _ctx.$t("Update/Back") : _ctx.$t("Add/Back")), 9, _hoisted_5),
                                _createElementVNode("button", {
                                    style: { "max-width": "6rem" },
                                    type: "submit",
                                    class: "btn btn-success btn-sm",
                                    disabled: isSubmitting,
                                    onClick: _cache[4] || (_cache[4] = function ($event) { return ($setup.submitModal(false)); })
                                }, _toDisplayString($setup.editMode ? _ctx.$t("Update") : _ctx.$t("Add")), 9, _hoisted_6)
                            ]; }),
                            _: 2
                        }, 1024)
                    ];
                }),
                _: 1
            }, 8, ["onSubmit", "validation-schema"])
        ])
    ]));
}
