import { __awaiter, __generator } from "tslib";
import { ref, onMounted } from "vue";
import * as yup from "yup";
import { Form } from "vee-validate";
import BaseField from "@/components/BaseField.vue";
import BaseCol from "@/components/BaseCol.vue";
import BaseRow from "@/components/BaseRow.vue";
import { createEditCategories, getsingleEditCategories, updateEditCategories } from "@/api/edit/CategoryApi";
import BaseImageUploadSingle from "@/components/BaseImageUploadSingle.vue";
import useNotyf from "@/composable/useNotyf";
import { translate } from "@/core/helpers/custom";
// Define Yup validation schema
var validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    categories: yup.array().min(1, "Category is required"),
    quantity: yup.number().required("Quantity is required").min(0, "Quantity must be greater than zero"),
    price: yup.number().required("Price is required").min(0, "Price cannot be negative"),
});
export default {
    name: "category-form-modal",
    components: {
        BaseImageUploadSingle: BaseImageUploadSingle,
        BaseRow: BaseRow,
        BaseCol: BaseCol,
        BaseField: BaseField,
        Form: Form,
    },
    props: {
        id: {
            type: Number,
        }
    },
    setup: function (props, _a) {
        var _this = this;
        var emit = _a.emit;
        var form = ref({
            name: "",
            sort_order: 0,
            status: true,
            description: "",
            meta_tag_title: "",
            meta_tag_description: "",
            meta_tag_keyword: "",
            parent_id: null,
            image: null,
            image_path: null,
        });
        var notification = useNotyf();
        var loading = ref(false);
        var isCategoryOptionsLoading = ref(false);
        var categoryData = ref([]);
        var editMode = ref(false);
        // Submit Function
        var submitModal = function (value) { return __awaiter(_this, void 0, void 0, function () {
            var response, error_1, response, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        loading.value = true;
                        if (!editMode.value) return [3 /*break*/, 6];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, updateEditCategories(form.value, props === null || props === void 0 ? void 0 : props.id)];
                    case 2:
                        response = _a.sent();
                        categoryData.value = response.data.data;
                        notification.success(translate(response.data.message));
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _a.sent();
                        console.log(error_1);
                        notification.error(translate(error_1.message));
                        return [3 /*break*/, 5];
                    case 4:
                        loading.value = false;
                        return [7 /*endfinally*/];
                    case 5: return [3 /*break*/, 10];
                    case 6:
                        _a.trys.push([6, 8, 9, 10]);
                        return [4 /*yield*/, createEditCategories(form.value)];
                    case 7:
                        response = _a.sent();
                        categoryData.value = response.data.data;
                        notification.success(translate(response.data.message));
                        return [3 /*break*/, 10];
                    case 8:
                        error_2 = _a.sent();
                        console.log(error_2);
                        notification.error(translate(error_2.message));
                        return [3 /*break*/, 10];
                    case 9:
                        loading.value = false;
                        return [7 /*endfinally*/];
                    case 10:
                        if (value) {
                            closeModal();
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        var handleFetchSingleCategory = function () { return __awaiter(_this, void 0, void 0, function () {
            var response, error_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        loading.value = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, getsingleEditCategories(props.id)];
                    case 2:
                        response = _a.sent();
                        // categoryData.value = response.data.data;
                        // notification.success(translate(response.data.message));
                        form.value = response.data.data;
                        console.log(form.value);
                        return [3 /*break*/, 5];
                    case 3:
                        error_3 = _a.sent();
                        console.log(error_3);
                        notification.error(translate(error_3.message));
                        return [3 /*break*/, 5];
                    case 4:
                        loading.value = false;
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        var closeModal = function () {
            emit("closeModal");
        };
        onMounted(function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!props.id) return [3 /*break*/, 2];
                        return [4 /*yield*/, handleFetchSingleCategory()];
                    case 1:
                        _a.sent();
                        editMode.value = true;
                        return [3 /*break*/, 3];
                    case 2:
                        editMode.value = false;
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); });
        return {
            form: form,
            closeModal: closeModal,
            submitModal: submitModal,
            validationSchema: validationSchema,
            isCategoryOptionsLoading: isCategoryOptionsLoading,
            categoryData: categoryData,
            editMode: editMode,
        };
    },
};
